import React, { useEffect, useState } from 'react';
import {
  // Avatar,
  Box, Button, FormLabel, Grid, Paper, Stack, TextField, Tooltip, Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import { Accordion } from '../components';
import { IModule, IRole } from './type';

const initialState = {
  name: '',
};
const Role: React.FC = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const roleId = queryParams.get('id');
  const permissions = ['Read', 'Write', 'Edit', 'Delete'];
  const [role, setRole] = useState<IRole>(initialState);
  const [modules, setModules] = useState<IModule[]>([]);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const navigate = useNavigate();
  const customStyles = {
    width: '100%',
    overflow: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '8px !important', // Note: Property name should be camelCase
    fontWeight: 500,
  };
  useEffect(() => {
    let permissionObj = {};
    permissions.forEach((per) => {
      permissionObj = {
        ...permissionObj,
        [per]: false,
      };
    });
    const fetchRoleDetails = async () => {
      if (roleId) {
        try {
          const { data: { data } } = await axios.get(`${process.env.REACT_APP_API_URL}/role/${roleId}`);
          const { module } = data;
          setRole(data); // Set the fetched role for editing
          setModules(module);
        } catch (error) {
          console.error('Error fetching role details:', error);
        }
      }
    };
    fetchRoleDetails();
    if (!roleId) {
      const fetchModules = async () => {
        const { data: { data = {} } = {} } = await axios.get(`${process.env.REACT_APP_API_URL}/module/getSubModules`);
        const Module = data.map((module: any) => ({
          module: module.name,
          // eslint-disable-next-line no-underscore-dangle
          id: module._id,
          permissions: { ...permissionObj },
          subModules: module.subModule.map((m: any) => ({
            subModule: m.name,
            // eslint-disable-next-line no-underscore-dangle
            id: m._id,
            permissions: { ...permissionObj },
          })),
        }));
        setModules([
          ...modules,
          ...Module,
        ]);
      };
      fetchModules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRole({
      ...role,
      [name]: value,
    });
  };
  const handleBack = () => {
    navigate('/roles');
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, mode: string, module: string, subModule: string = ''):void => {
    const { name, checked } = event.target;
    const updatedState = modules.map((feature:any) => {
      if (mode === 'modules') {
        if (feature.module === module) {
          const data = feature.subModules.map((sub:any) => ({
            ...sub,
            permissions: {
              ...sub.permissions,
              [name]: checked,
            },
          }));
          return {
            ...feature,
            permissions: {
              ...feature.permissions,
              [name]: checked,
            },
            subModules: [...data],
          };
        }
      }
      if (mode === 'subModules') {
        if (feature.module === module) {
          const data = feature.subModules.map((sub:any) => {
            if (sub.subModule === subModule) {
              return {
                ...sub,
                permissions: {
                  ...sub.permissions,
                  [name]: checked,
                },
              };
            }
            return sub;
          });
          return {
            ...feature,
            subModules: [...data],
          };
        }
      }
      return feature;
    });
    setModules(updatedState);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!role.name.trim()) {
      setIsNameEmpty(true); // Set the error state to true
      return; // Prevent form submission
    }
    setIsNameEmpty(false);
    let formData;
    const dataToSave = modules.map((item: any) => ({
      module: item.id ? item.id : item.moduleId,
      // id: module._id,
      permissions: item.permissions,
      subModules: item.subModules.map((m: any) => ({
        subModule: m.id ? m.id : m.subModuleId,
        // id: m._id,
        permissions: m.permissions,
      })),
    }));
    if (roleId) {
      const { name } = role;
      formData = {
        module: [...dataToSave],
        id: roleId,
        name,
      };
    } else {
      formData = {
        modules: [...dataToSave],
        ...role,
      };
    }
    if (roleId) {
      // Update existing role
      try {
        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/role/`, formData);
        if (data.error) {
          navigate('/roles?param=3');
        } else if (data.message === 'No matching record found') {
          navigate('/roles?param=4');
        } else {
          navigate('/roles?param=2');
        }
      } catch (error) {
        console.error('Error updating role:', error);
      }
    } else {
      // Create new role
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/role`, formData);
        if (data.error) {
          navigate('/roles?param=3');
        } else {
          setModules([...modules, data]);
          navigate('/roles?param=1');
        }
      } catch (error) {
        console.error('Error creating role:', error);
      }
    }
  };
  return (
    <Grid item lg={12} xs={12} sm={12}>
      <Paper
        // className="hFull_screen"
        sx={customStyles}
      >
        <Box mt={2} sx={{ background: 'white' }} p={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">
              Add Role
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2} my={2}>
            <Stack direction="row" alignItems="center">
              <FormLabel
                sx={{
                  fontSize: '1rem', fontWeight: '900', m: '0', color: '#000',
                }}
                required
              >
                Name
              </FormLabel>
              <Tooltip title="Name is required" sx={{ marginLeft: '0.5rem' }}>
                <Info />
              </Tooltip>
            </Stack>
            <TextField
              name="name"
              id="outlined-basic"
              variant="outlined"
              onChange={handleChange}
              value={role?.name}
              required
              inputProps={{ maxLength: 30 }}
            />
            {isNameEmpty && (
            <Typography variant="body2" color="error">
              Name is required
            </Typography>
            )}
          </Stack>
          <Stack direction="column" spacing={2} mt={4}>
            <Typography variant="body1" sx={{ fontWeight: '900' }}>Select Modules</Typography>
            <Accordion modules={modules} onHandleSwitchChange={handleSwitchChange} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" mt={4}>
            <Button variant="contained" onClick={handleBack}>Back</Button>
            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
          </Stack>
        </Box>
      </Paper>
      {/* <div className="rbac_content_header">
        <Typography variant="h5">
          {' '}
          <Avatar className="title_icn" alt="Remy Sharp" src="../../../role_icon.svg" />
          Add Role
        </Typography>
      </div> */}

    </Grid>
  );
};

export default Role;
