import React from 'react';
import {
  Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, Toolbar,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 80;
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = React.useState('/roles');
  const baseUrl = window.location.origin;

  const handleItemClick = (route: any) => {
    navigate(route);
    setActiveItem(route);
  };

  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const list = () => (
    <List sx={{ pt: '20px', cursor: 'pointer' }}>
      {[
        // { text: 'Home', imageURL: 'Home.svg', route: '/dashboard' },
        { text: 'Roles', imageURL: 'Roles.svg', route: '/roles' },
        { text: 'Modules', imageURL: 'module.svg', route: '/module' },
        { text: 'Sub-Modules', imageURL: 'submodule.svg', route: '/sub-module' },
      ].map(({ text, imageURL, route }) => (
        <ListItem
          sx={{
            alignContent: 'space-between',
            alignItems: 'center',
            color: '#fff',
            display: 'grid',
            justifyContent: 'center',
            borderLeft: activeItem.startsWith(route) ? '3px solid #DE4E35' : 'none',
          }}
          key={text}
          onClick={() => handleItemClick(route)}
        >
          <ListItemIcon sx={{ display: 'block', textAlign: 'center' }}>
            <img src={`${baseUrl}/${imageURL}`} alt="svg" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: 'center' }} primary={text} />
        </ListItem>
      ))}
    </List>
  );
  return (
    <Grid>
      <Drawer
        className="side-bar-container"
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: '#2559C3',
          },
        }}
      >
        <Toolbar />
        {list()}
      </Drawer>
    </Grid>
  );
};

export default Sidebar;
