import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import {
  Button, Stack, Typography, Paper, Box, List, ListItem, ListItemIcon, ListItemText, Grid,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ModuleData } from './type';
import { Loader, Toast } from '../../components';
import { Severity } from '../../components/Toast/type';
import AlertDialog from '../../components/Alertdialog/Alertdialog';

let content;
const Role = () => {
  const [rows, setRows] = useState<ModuleData[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogid, setDialogid] = useState(String);
  const [info, setInfo] = useState<{ message: string, status: number, open: boolean, severity: Severity }>({
    message: '', status: 500, severity: Severity.ERROR, open: false,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Access individual query parameters
  const param = queryParams.get('param');
  //   const [module, setModule] = useState<ModuleData>();
  //   const [open, setOpen] = useState<boolean>(false);
  //   const [isEdit, setIsEdit] = useState<boolean>(false);

  //   const handleEdit = (row: ModuleData) => {
  //     setModule(row);
  //     setOpen(true);
  //     setIsEdit(true);
  //   };

  useEffect(() => {
    try {
      if (param === '1') {
        const isshow = true;
        setInfo({
          ...info, message: 'Role Added Succesfully', open: isshow, severity: Severity.SUCCESS,
        });
        navigate('/roles');
      }
      if (param === '2') {
        const isshow = true;
        setInfo({
          ...info, message: 'Role Updated Succesfully', open: isshow, severity: Severity.SUCCESS,
        });
        navigate('/roles');
      }
      if (param === '3') {
        const isshow = true;
        setInfo({
          ...info, message: 'Role Name Already Exist', open: isshow, severity: Severity.ERROR,
        });
        navigate('/roles');
      }
      if (param === '4') {
        const isshow = true;
        setInfo({
          ...info, message: 'No matching record found', open: isshow, severity: Severity.ERROR,
        });
        navigate('/roles');
      }
      // const fetchModules = async () => {
      //   setLoading(true);
      //   const { data: { data = {} } = {} } = await axios.get(`${process.env.REACT_APP_API_URL}/role`);
      //   setRows(data);
      //   setLoading(false);
      // };
      const fetchModules = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/role`);
          const responseData = response.data;
          if (Array.isArray(responseData.data)) {
            setRows(responseData.data);
          } else {
            setRows([]); // Set it as an empty array if the response data is not an array
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setRows([]); // Set it as an empty array in case of an error
        }
      };

      fetchModules();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (id: string) => {
    setOpenDialog(true);
    setRows(rows.filter((row) => row.id !== id));
    const { data: { data = {} } = {} } = await axios.delete(`${process.env.REACT_APP_API_URL}/role/${id}`);
    setOpenDialog(false);
    setRows(data.deletedCount === 1 ? rows.filter((row) => row.id !== id) : rows);
    setInfo({
      ...info, message: 'Role Deleted Succesfully', open: true, severity: Severity.ERROR,
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDialog = (id: string) => {
    setDialogid(id);
    setOpenDialog(true);
  };

  if (loading) {
    content = <Loader open={loading} />;
  } else if (rows.length > 0) {
    content = (
      <List sx={{ padding: '15px' }} className="tbl_view">
        {rows.map((row) => (
          <ListItem
            key={row.id}
            sx={{
              border: '1px solid #D3D3D3', borderRadius: '10px', padding: '1rem 1rem', mb: '1rem',
            }}
          >
            <ListItemText id={row.name} primary={row.name} />
            <ListItemIcon>
              <Edit onClick={() => navigate(`/roles/add?id=${row.id}`)} sx={{ color: '#2559C3' }} />
              <Delete onClick={() => handleDialog(row.id)} sx={{ color: '#DE4E35' }} />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    );
  } else {
    content = <Typography variant="body1" style={{ padding: '15px', textAlign: 'center' }}>No data to display</Typography>;
  }

  return (
    <Grid flexGrow={1}>
      {loading && <Loader open={loading} />}
      {info.open && (
        <Toast
          open={info.open}
          message={info.message}
          severity={info.severity}
          onClose={() => setInfo({ ...info, open: false })}
        />
      )}
      {openDialog && <AlertDialog open={openDialog} onClose={handleClose} onDelete={() => handleDelete(dialogid)} />}

      <Stack className="rbac_content_header" direction="row" justifyContent="space-between" flexGrow={1} alignItems="center" mb={2}>
        <Typography variant="h5">
          {' '}
          <Avatar className="title_icn" alt="Remy Sharp" src="../../../role_icon.svg" />
          Roles
        </Typography>
        <Button className="text-capitalize" variant="contained" onClick={() => navigate('/roles/add')}>Add New Role + </Button>
      </Stack>
      <Box style={{
        display: 'flex', flexDirection: 'column', height: 'auto', flexGrow: 1,
      }}
      >
        <Paper
          className="hFull_screen"
          sx={{
            width: '100%', overflow: 'hidden',
          }}
        >
          <Stack direction="row" justifyContent="space-between" p={2} sx={{ backgroundColor: '#e9eef9' }}>
            <Box>Name</Box>
            {/* <Box>Module</Box>
          <Box>Sub Module</Box>
          <Box>Code</Box>
          <Box>Permission</Box> */}
            <Box>Actions</Box>
          </Stack>
          {content}
        </Paper>
      </Box>
    </Grid>
  );
};

export default Role;
